import { Fragment, useContext, useEffect, useRef, useState } from "react";

import closeDarkIcon from '../../../icons/cross-white.png';
import closeLightIcon from '../../../icons/cross.png';

import closedDarkIcon from '../../../icons/closed-dark.png';

import Spinner from "../../../components/spinner/spinner";

import { AlertContext } from "../../../components/alert/alertContext";
import { ViewPaymentDetailsContext } from "./viewPaymentDetailsContext";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";

const ViewPaymentDetails = ({ pickerWidth, sale, cancelPayment }) => {
    const { appTheme, appLanguage, formatRationalNumber, getTimeInString } = useContext(AppContext);
    const { alertActive } = useContext(AlertContext);
    const { authorizedUsers, accessTo, setSaleListener, setAuthorizedUsersListener } = useContext(AuthContext);
    const { setAlertData, setAlertActive, setOnAgree } = useContext(AlertContext);

    const {
        viewPaymentDetailsActive,
        paymentRef,
        setViewPaymentDetailsActive,
        loading,
    } = useContext(ViewPaymentDetailsContext);

    // This prevent the page send a request twice to the backend
    const [generalState] = useState("0000");

    useEffect(() => {
        setSaleListener(true);
        setAuthorizedUsersListener(true);
        // eslint-disable-next-line
    }, [generalState]);

    const getPayment = () => {
        if (sale && paymentRef) {
            return sale.payments.find(item => item.ref === paymentRef);
        } else {
            return null;
        }
    }

    const [payment, setPayment] = useState(getPayment());

    useEffect(() => {
        setPayment(getPayment());
        // eslint-disable-next-line
    }, [sale, paymentRef])

    let menuRef = useRef();

    useEffect(() => {
        if (viewPaymentDetailsActive) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    if ((!alertActive) && (!loading)) {
                        setViewPaymentDetailsActive(false);
                    }
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickClose = () => {
        setViewPaymentDetailsActive(false);
    }

    const getDateInString = (date) => {
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const meses = appLanguage.en ? ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"] : ["Ene", "Feb", "Mar", "Abr", "Mayo", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"];
        return `${day} ${meses[month]}, ${year}`;
    }

    const getDate = (date) => {
        const dateObj = new Date(date)
        return getDateInString(dateObj);
    }

    const getTime = (date) => {
        const dateObj = new Date(date);
        return getTimeInString(dateObj);
    }

    const getPaymentStatus = (status) => {
        if (sale.status !== "canceled") {
            switch (status) {
                case "successful":
                    return appLanguage.en ? "Successful" : "Exitoso"
                case "pending":
                    return appLanguage.en ? "Processing" : "Procesando"
                default:
                    return appLanguage.en ? "Canceled" : "Cancelado"
            }
        } else {
            return appLanguage.en ? "Canceled" : "Cancelado"
        }
    }

    const getPaymentStatusColor = (status) => {
        if (sale.status !== "canceled") {
            switch (status) {
                case "successful":
                    return "green"
                case "pending":
                    return appTheme.dark ? "rgba(255, 166, 0, 0.774)" : "orange"
                default:
                    return "rgba(189, 47, 47, 0.822"
            }
        } else {
            return "rgba(189, 47, 47, 0.822"
        }
    }

    const getPaymentMethod = (paymentMethod) => {
        switch (paymentMethod) {
            case "cash":
                return appLanguage.en ? "Cash" : "Efectivo";
            case "card":
                return appLanguage.en ? "Credit/debit card" : "Tarjeta de crédito/débito";
            case "bankTransfer":
                return appLanguage.en ? "Bank transfer" : "Transferencia bancaria";
            case "link":
                return appLanguage.en ? "Payment link" : "Enlace de pago";
            case "check":
                return appLanguage.en ? "Check" : "Cheque";
            case "paypal":
                return "PayPal";
            default:
                return appLanguage.en ? "Not defined" : "No definido";
        }
    }

    const getAuthUserName = (userId) => {
        if (authorizedUsers) {
            const user = authorizedUsers.find(user => user.id === userId);
            return user ? user.name : "--";
        } else {
            return "--"
        }
    }

    const canBeCancel = () => {
        if (sale && sale.status !== "canceled" && payment && payment.status === "successful") {
            return true;
        } else {
            return false;
        }
    }

    const onConfirmDeletion = () => {
        cancelPayment(paymentRef);
    }

    const onClickCancelPayment = () => {
        if (sale && sale.status !== "canceled" && payment && payment.status === "successful") {
            setAlertData({
                type: 'question',
                title: {
                    en: `Cancel payment?`,
                    es: `¿Cancelar pago?`
                },
                code: '',
                description: {
                    en: `Are you sure you want to cancel the payment ref ${paymentRef} for RD$ ${formatRationalNumber(payment.cashAmount)}?`,
                    es: `¿Estás seguro de que deseas cancelar el pago ref ${paymentRef} por RD$ ${formatRationalNumber(payment.cashAmount)}?`
                }
            });
            setOnAgree(() => onConfirmDeletion);
            setAlertActive(true);
        }
    }

    return (
        <div
            style={{
                WebkitUserSelect: "auto",
                WebkitTouchCallout: "auto",
                MozUserSelect: "auto",
                MsUserSelect: "auto",
                userSelect: "auto",
            }}
        >
            {viewPaymentDetailsActive ? <div className='picker-date-lock-screen' style={{ width: pickerWidth, minHeight: "calc(100vh - 65px)" }} /> : null}
            <div ref={menuRef} className={`picker-date-genaral-card ${viewPaymentDetailsActive ? 'active' : 'inactive'}`} style={{ marginLeft: `calc(${pickerWidth / 2}px - 175px)`, marginTop: "75px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>

                <div className='picker-date-general-despcrition' style={{ display: "flex", minHeight: "280px" }}>
                    {loading ?
                        <div style={{ marginTop: "40px" }}><Spinner /></div>
                    :
                        <Fragment>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                                <h4 style={{ marginLeft: "15px", fontWeight: "600" }}>
                                    {appLanguage.en ? "Payment details" : "Detalles del pago"}
                                </h4>

                                <button
                                    onClick={onClickClose}
                                    className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                                >
                                    <img
                                        className="see-details-transations-img"
                                        src={appTheme.dark ? closeDarkIcon : closeLightIcon}
                                        alt="close"
                                    />
                                </button>
                            </div>

                            <div style={{ display: "flex", flexDirection: "column", minHeight: "200px" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        marginTop: "0px",
                                        marginBottom: "15px",
                                        flexDirection: "column"
                                    }}
                                >
                                    {payment && sale ? <Fragment>

                                        <p
                                            style={{
                                                marginTop: "5px",
                                                fontSize: "20px",
                                                textAlign: "center",
                                                fontWeight: "600",
                                                marginBottom: "20px"
                                            }}
                                        >
                                            RD$ {formatRationalNumber(payment.cashAmount)}
                                        </p>

                                        <p style={{ margin: "10px 15px" }}>
                                            {getTime(payment.time)} - {getDate(payment.time)}
                                        </p>

                                        <p style={{ margin: "5px 15px" }}>
                                            <b>{appLanguage.en ? "Reference" : "Referencia"}:</b> {payment.ref}
                                        </p>

                                        <p style={{ margin: "5px 15px" }}>
                                            <b>{appLanguage.en ? "State" : "Estado"}:</b> <span className="customer-detaines-info-out-of-stock-span-cont" style={{ fontSize: "11px", background: getPaymentStatusColor(payment.status) }}>{getPaymentStatus(payment.status)}</span>
                                        </p>

                                        <p style={{
                                            margin: "5px 15px",
                                            whiteSpace: "nowrap", 
                                            "WebkitBoxOrient": "vertical",
                                            "WebkitLineClamp": "1",
                                            "lineClamp": "1",
                                            "overflow": "hidden",
                                            "textOverflow": "ellipsis"
                                        }}>
                                            <b>{appLanguage.en ? "Processing by" : "Procesando por"}:</b>  {sale.soldBy ? getAuthUserName(sale.soldBy) : "--"}
                                        </p>

                                        <p style={{ margin: "5px 15px" }}>
                                            <b>{appLanguage.en ? "Method" : "Método"}:</b> {getPaymentMethod(payment.paymentMethod)}
                                        </p>

                                        {payment.paymentNotes && payment.paymentNotes.trim() !== "" ?
                                            <p style={{ margin: "5px 15px" }}>
                                                <b>{appLanguage.en ? "Notes" : "Notas"}:</b> {payment.paymentNotes}
                                            </p>
                                        : null}

                                        {accessTo.salesSup ?
                                            <div 
                                                style={{
                                                    display: "flex", 
                                                    width: "100%", 
                                                    justifyContent: "center", 
                                                    alignItems: "center",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <button 
                                                    onClick={onClickCancelPayment}
                                                    disabled={!canBeCancel()}
                                                    className="new-customer-bottons-button-save"
                                                    style={canBeCancel() ? { maxWidth: "190px" } : {
                                                        margin: "0px 0px 15px 15px",
                                                        backgroundColor: "#3f526698",
                                                        color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                        cursor: "not-allowed"
                                                    }}
                                                >   
                                                    <img src={closedDarkIcon} style={{marginRight: "10px"}} alt="Cancel payment" />
                                                    {appLanguage.en ? <p>Cancel payment</p> : <p>Cancelar pago</p>}
                                                </button>
                                            </div>
                                        : null}

                                    </Fragment> : null}
                                </div>
                            </div>

                        </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default ViewPaymentDetails;