import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const ViewPaymentDetailsContext = createContext();

const ViewPaymentDetailsProvider = (props) => {

    const location = useLocation();

    const [ viewPaymentDetailsActive, setViewPaymentDetailsActive ] = useState(false);
    const [ paymentRef, setPaymentRef ] = useState(null);

    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        if (viewPaymentDetailsActive) {
            if ((!(location.pathname === "/pagos-a-venta") && (!loading))) {
                setViewPaymentDetailsActive(false);
                setPaymentRef(null)
            }
        }
    // eslint-disable-next-line
    }, [location, loading]);

    return (
        <ViewPaymentDetailsContext.Provider
            value={{ 
                viewPaymentDetailsActive,
                paymentRef,
                loading,
                setViewPaymentDetailsActive,
                setPaymentRef,
                setLoading,
            }}
        >
            {props.children}
        </ViewPaymentDetailsContext.Provider>
    )
}

export default ViewPaymentDetailsProvider;