import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";

import dollarSignDark from "../../../icons/dollar-sign-dark.png";
import dollarSignLight from "../../../icons/dollar-sign-light.png";

const PaymentSaleMiniCard = ({ payment, onClickLink, width, saleStatus  }) => {
    const { appTheme, appLanguage, isTouchEnabled, formatRationalNumber, getTimeInString, getShortDateEn, getShortDateEs} = useContext(AppContext);

    const validateGetShortDateEn = (time) => {
        if (time) {
            return getShortDateEn(time)
        } else {
            return "Date not available"
        }
    }

    const validateGetShortDateEs = (time) => {
        if (time) {
            return getShortDateEs(time)
        } else {
            return "Fecha no disponible"
        }
    }

    const [ time, setTime ] = useState(appLanguage.en ? validateGetShortDateEn(payment.time) : validateGetShortDateEs(payment.time));

    useEffect(() => {
        setTime(appLanguage.en ? validateGetShortDateEn(payment.time) : validateGetShortDateEs(payment.time));
    // eslint-disable-next-line
    }, [appLanguage]);

    const getPaymentStatus = (status) => {
        if (saleStatus !== "canceled") {
            switch (status) {
                case "successful":
                    return appLanguage.en ? "Successful" : "Exitoso"
                case undefined:
                    return appLanguage.en ? "Successful" : "Exitoso"
                case "pending":
                    return appLanguage.en ? "Processing" : "Procesando"
                default:
                    return appLanguage.en ? "Canceled" : "Cancelado"
            }
        } else {
            return appLanguage.en ? "Canceled" : "Cancelado"
        }
    }

    const getPaymentStatusColor = (status) => {
        if (saleStatus !== "canceled") {
            switch (status) {
                case "successful":
                    return "green"
                case undefined:
                    return "green"
                case "pending":
                    return appTheme.dark ? "rgba(255, 166, 0, 0.774)" : "orange"
                default:
                    return "rgba(189, 47, 47, 0.822)"
            }
        } else {
            return "rgba(189, 47, 47, 0.822)"
        }
    }

    const getPaymentMethod = (paymentMethod) => {
        switch (paymentMethod) {
            case "cash":
                return appLanguage.en ? "Cash" : "Efectivo";
            case "card":
                return appLanguage.en ? "Credit/debit card" : "Tarjeta de crédito/débito";
            case "bankTransfer":
                return appLanguage.en ? "Bank transfer" : "Transferencia bancaria";
            case "link":
                return appLanguage.en ? "Payment link" : "Enlace de pago";
            case "check":
                return appLanguage.en ? "Check" : "Cheque";
            case "paypal":
                return "PayPal";
            default:
                return appLanguage.en ? "Not defined" : "No definido";
        }
    }

    return (
        <div
            onClick={() => onClickLink(payment.ref)}
            className={`recently-opened-card-div ${appTheme.dark ? (isTouchEnabled() ? "recently-opened-card-div-dark-NOHOVER" : "recently-opened-card-div-dark-HOVER") : (isTouchEnabled() ? "recently-opened-card-div-light-NOHOVER" : "recently-opened-card-div-light-HOVER")}`}
            style={{ maxWidth: "100%" }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "190px",
                }}
            >
                <div style={{width: "40px", marginRight: "8px"}}>
                    <span style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>
                        <img style={{width: "25px", height: "25px"}} src={appTheme.dark ? dollarSignDark : dollarSignLight } alt="Status" />
                    </span>
                </div>

                <div>
                    <p className="products-mini-view-product-ref">
                        <span className="customer-detaines-info-out-of-stock-span-cont" style={{ fontSize: "11px", background: getPaymentStatusColor(payment.status) }}>{getPaymentStatus(payment.status)}</span>
                    </p>
                
                    <p 
                        className="products-mini-view-product-price" 
                        style={{fontSize: "15px"}}
                    >
                        RD$ {formatRationalNumber(payment.cashAmount)}
                    </p>
                </div>
            </div>

            {width > 530 ?
                <div 
                    style={{
                        maxWidth: "170px",
                        minWidth: "170px",
                        paddingLeft: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}
                >
                    <p><b>{appLanguage.en ? "Payment method" : "Método de pago"}</b>:</p>
                    <p style={{marginTop: "4px"}}>{getPaymentMethod(payment.paymentMethod)}</p>
                </div> 
            : null}

            <div 
                className="recently-opened-section2" 
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}
            >
                <p className="recently-opened-hours">{payment.time ? getTimeInString(payment.time) : "--"}</p>
                <p style={{marginTop: "4px"}}>{time}</p>
            </div>
        </div>
    );
}

export default PaymentSaleMiniCard;