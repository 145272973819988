import { useContext, Fragment } from "react";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { uid } from "uid";

import checkIcon from '../../img/check.svg';
import uncheckIcon from '../../img/uncheck.svg';
import plusIcon from '../../img/plus.svg';

import nextIcon from '../../icons/arrow-small-right-white.png';
import freeIcon from '../../img/gift-box.svg';

import './pricingCard.scss';

const PricingCard = ({plan, freeTrial}) => {

    const { appLanguage, appTheme, formatRationalNumber } = useContext(AppContext);

    const navigate = useNavigate();

    const onClickContinue = () => {
        const planDetails = {
            ...plan,
            freeTrial
        }
        navigate('/crear-cuenta-de-negocio', {state : planDetails, replace: true });
    }

    return (
        <div className={`pricing-cre-acc-card ${appTheme.dark ? 'dar-box-sha-keep-hover dar-grad-bg-solid input-border-box-dark' : 'lig-box-sha-keep-hover lig-grad-bg-solid input-border-box-light'}`}>
            {appLanguage.en ? <Fragment>
                <div className="pricing-cre-acc-title">
                    <h3>{plan ? plan.name.en : "..."}</h3>
                    <p>RD$ <b>{plan ? formatRationalNumber(plan.price) : "..."}</b> monthly </p>
                </div>
                {freeTrial ? 
                    <div className="pricing-cre-acc-free-trial">
                        <img src={freeIcon} alt="Free Trial" />
                        <p>14 days free trial</p>
                    </div>
                : null}

                {plan && plan.mostPopular ? 
                    <p className="pricing-cre-acc-most-popular-label">Most popular</p>    
                : null}

                <ul>
                    {plan ? <Fragment>
                        {plan.items.included.en.map((item) => {
                            return <li key={uid()}>
                                <img className="pricing-cre-acc-icons" src={checkIcon} alt="Included" /> {item}
                            </li>
                        })}
                        {plan.items.excluded.en.map((item) => {
                            return <li key={uid()}>
                                <img className="pricing-cre-acc-icons" src={uncheckIcon} alt="excluded" />  <s>{item}</s>
                            </li>
                        })}
                        <br />
                        {plan.items.extra.en.map((item) => {
                            return <li key={uid()}>
                                <img className="pricing-cre-acc-icons" src={plusIcon} alt="excluded" />  {item}
                            </li>
                        })}
                    </Fragment>: "..."}
                </ul>

            </Fragment> : <Fragment>
                <div className="pricing-cre-account-title">
                    <h3>{plan ? plan.name.es : "..."}</h3>
                    <p>RD$ <b>{plan ? formatRationalNumber(plan.price) : "..."}</b> mensual </p>
                </div>
                {freeTrial ? 
                    <div className="pricing-cre-acc-free-trial">
                        <img src={freeIcon} alt="Free Trial" />
                        <p>14 días de prueba gratis</p>
                    </div>
                : null}

                {plan && plan.mostPopular ? 
                    <p className="pricing-cre-acc-most-popular-label">Más popular </p>
                : null}

                <ul>
                    {plan ? <Fragment>
                        {plan.items.included.es.map((item) => {
                            return <li key={uid()}>
                                <img className="pricing-cre-acc-icons" src={checkIcon} alt="Included" /> {item}
                            </li>
                        })}
                        {plan.items.excluded.es.map((item) => {
                            return <li key={uid()}>
                                <img className="pricing-cre-acc-icons" src={uncheckIcon} alt="excluded" /> {item}
                            </li>
                        })}
                        <br />
                        {plan.items.extra.es.map((item) => {
                            return <li key={uid()}>
                                <img className="pricing-cre-acc-icons" src={plusIcon} alt="excluded" /> {item}
                            </li>
                        })}
                    </Fragment>: "..."}
                </ul>
            </Fragment>}
            <button
                className={`continue-cre-acc-button-container`}
                onClick={onClickContinue}
            >
                <img
                    src={nextIcon}
                    alt="Next button"
                    className='outside-cre-acc-button-container-img'
                />
                <p className='outside-cre-acc-button-container-text'>{appLanguage.en ? 'Continue' : 'Continuar'}</p>
            </button>
        </div>
    )
}

export default PricingCard;
