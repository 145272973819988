import React, { Fragment, useContext, useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";

import firebase from "../../../firebase";
import { onSnapshot, doc } from "firebase/firestore";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import upDarkIcon from "../../../icons/up-dark.png";

import emptyFolderImg from "../../../img/empty-folder.svg";

import copyIconDark from "../../../icons/copy.png";
import copyIconLight from "../../../icons/copyLight.png";

import completedIcon from "../../../icons/sale-completed.png";
import pendingIcon from "../../../icons/sale-pending.png";
import cancelIcon from "../../../icons/sale-cancel.png";

import closeDarkIcon from "../../../icons/closed-dark.png";
import closeLightIcon from "../../../icons/closed-light.png";

import crossIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";

import { uid } from "uid";
import Spinner from "../../../components/spinner/spinner";
import ConnectionError from "../../../components/connection-error/connectionError";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import { AlertContext } from "../../../components/alert/alertContext";



const SaleHistoryPage = () => {
    const { appTheme, appLanguage, isTouchEnabled, getTimeInString } = useContext(AppContext);
    const { accountData, accessTo, setSaleListener, setAuthorizedUsersListener, userData } = useContext(AuthContext);
    const { desktopView } = useContext(SideMenuContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, getErrorDescription, setOnAgree } = useContext(AlertContext);

    const [createNewNote, setCreateNewNote] = useState(false);
    const [noteData, setNoteData] = useState("");
    const [inputAlert, setInputAlert] = useState(false);

    // This prevent the page send a request twice to the backend
    const [generalState] = useState("0000");

    useEffect(() => {
        setSaleListener(true);
        setAuthorizedUsersListener(true);
        // eslint-disable-next-line
    }, [generalState]);

    const location = useLocation();

    const [saleId, setSaleId] = useState(null);

    const [request, setRequest] = useState("0000");

    const [showConnectionError, setShowConnectionError] = useState(false);

    const [loadingNotes, setLoadingNotes] = useState(false);

    const [sale, setSale] = useState(null);

    // Now I have to listeng to the product doc
    useEffect(() => {
        if (saleId && (request !== "0000")) {
            setShowConnectionError(false);
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/sales`, saleId.id), (doc) => {
                const saleData = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete saleData.searchKeys;

                if (doc.data()) {
                    setSale(saleData);
                    setShowConnectionError(false);
                } else {
                    setShowConnectionError(true);
                }
            }, err => {
                console.error(err);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [request]);

    const onTryAgain = () => {
        setRequest(uid());
    }

    const navigate = useNavigate();


    useEffect(() => {
        if (location.state) {
            setSaleId({
                "id": location.state.id
            });
            setRequest(uid());
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.sales)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        navigate(null, { replace: true, state: saleId });
        // eslint-disable-next-line
    }, [saleId]);

    const getSaleIcon = (status) => {
        switch (status) {
            case "completed":
                return completedIcon;
            case "canceled":
                return cancelIcon;
            default:
                return pendingIcon;
        }
    }

    const getSaleStatus = (status) => {
        switch (status) {
            case "completed":
                return appLanguage.en ? "Completed" : "Completa";
            case "canceled":
                return appLanguage.en ? "Canceled" : "Cancelada";
            default:
                return appLanguage.en ? "Pending" : "Pendiente";
        }
    }

    const copySaleId = () => {
        try {
            const text = sale.id;
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The code has been copied',
                    es: 'El código ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const [onTop, setOnTop] = useState(true);
    const [topRef, setTopRef] = useState(null);

    const setTopReference = (inputElement) => {
        setTopRef(inputElement);
    }

    const handleClickTop = () => {
        if (topRef) {
            topRef.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const toObserver = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setOnTop(true);
                } else {
                    setOnTop(false);
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            }
        );

        if (topRef) {
            toObserver.observe(topRef);
        }

        return () => {
            if (topRef) {
                // eslint-disable-next-line
                toObserver.unobserve(topRef);
            }
        };
    }, [topRef]);

    const getDateInString = (date) => {
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const meses = appLanguage.en ? ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"] : ["Ene", "Feb", "Mar", "Abr", "Mayo", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"];
        return `${day} ${meses[month]}, ${year}`;
    }

    const getDate = (date) => {
        const dateObj = new Date(date)
        return getDateInString(dateObj);
    }

    const getTime = (date) => {
        const dateObj = new Date(date)
        return getTimeInString(dateObj);
    }

    const onClickAdd = () => {
        setCreateNewNote(true);
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    const onCancel = e => {
        e.preventDefault();
        setCreateNewNote(false);
        setInputAlert(false);
        setNoteData("");
    }

    const onSave = async (e) => {
        e.preventDefault();
        if (noteData.trim() === "") {
            setInputAlert(true);
        } else {
            setInputAlert(false);
            const res = await onClickSave();
            if (res) {
                handleClickTop();
            }
        }
    }

    const onChange = e => {
        setNoteData(e.target.value);
        setInputAlert(false);
    }

    const onDeleteNote = id => {
        onClickDelete(id);
    }

    async function onClickSave() {
        setLoadingNotes(true);
       
        try {
            const res = await firebase.useSetSaleNote({
                "businessId": accountData.id,
                "saleId": saleId.id,
                "note": noteData,
            });

            setSale(res);

            setNoteData("");
            setCreateNewNote(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Note created successfully',
                    es: 'Nota creada exitosamente',
                }
            });

            setLoadingNotes(false);
            return true;
        } catch (error) {
            console.error(error);
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error when trying to create note',
                    es: 'Error al tratar de crear la nota'
                },
                code: "error",
                description: getErrorDescription("error")
            });
            setAlertActive(true);

            setLoadingNotes(false);
            return false;
        }
    }

    async function deleteNote() {
        setLoadingNotes(true);
        setAlertActive(false);

        const toBeDeleted = JSON.parse(localStorage.getItem('toBeDeleted'));

        try {
            await firebase.useDeleteSaleNote({
                "businessId": accountData.id,
                "saleId": saleId.id,
                "noteId": toBeDeleted,
            });

            setNoteData("");
            setCreateNewNote(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Note deleted successfully',
                    es: 'Nota eliminada exitosamente',
                }
            });

            setLoadingNotes(false);
        } catch (error) {
            console.error(error);
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error when trying to delete note',
                    es: 'Error al tratar de eliminar la nota'
                },
                code: "error",
                description: getErrorDescription("error")
            });
            setAlertActive(true);
            setLoadingNotes(false);
        }

        localStorage.removeItem("toBeDeleted");
    }

    const onClickDelete = (id) => {
        localStorage.setItem("toBeDeleted", JSON.stringify(id));

        setAlertData({
            type: 'question',
            title: {
                en: 'Delete note?',
                es: 'Eliminar nota'
            },
            code: '',
            description: {
                en: `Are you sure you want to delete the selected note?`,
                es: `¿Estás seguro de que deseas eliminar la nota seleccionada?`
            }
        });
        setOnAgree(() => deleteNote);
        setAlertActive(true);
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Sale history" : "Historial de la venta"}</title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.sales ? <Fragment>
                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Sale history" : "Historial de la venta"}
                                    />
                                    : null}

                                {showConnectionError ? <ConnectionError onTryAgain={onTryAgain} /> :
                                    (sale ?
                                        <Fragment>
                                            <div style={{ marginTop: "25px", display: "flex", width: "100%", justifyContent: "center" }}>
                                                {/** Main container for seccion maxWidth: "745px" */}
                                                <div style={{ width: "100%", maxWidth: "740px" }}>
                                                    {/** Sale heather */}
                                                    <div className="unselectable" style={{ display: "flex", width: "100%", marginBottom: "20px" }}>
                                                        <div
                                                            className={`shoppo-cart-cust-card-div ${appTheme.dark ? "shoppo-cart-cust-card-div-dark" : "shoppo-cart-cust-card-div-light"}`}
                                                            style={{
                                                                display: "flex",
                                                                paddingLeft: "10px",
                                                                borderRadius: "100px",
                                                                margin: "0px 20px 0 20px",
                                                                width: "calc(100% - 40px)",
                                                                height: "40px",
                                                                minHeight: "36px",
                                                            }}
                                                        >
                                                            {sale ? <Fragment>
                                                                <img
                                                                    style={{
                                                                        width: "25px",
                                                                        height: "25px",
                                                                        marginLeft: "0px",
                                                                    }}
                                                                    src={getSaleIcon(sale.status)}
                                                                    alt="Status"
                                                                />

                                                                <p style={{ whiteSpace: "nowrap", fontSize: "16px", fontWeight: 500, marginLeft: "15px" }}>
                                                                    {getSaleStatus(sale.status)}
                                                                </p>

                                                                <span
                                                                    onClick={copySaleId}
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "6px 10px",
                                                                        marginRight: "5px"
                                                                    }}
                                                                    className={`sale-copy-id-successful ${isTouchEnabled() ? "sale-copy-id-successful-no-hover" : "sale-copy-id-successful-hover"}`}
                                                                >
                                                                    <p style={{ marginRight: "10px" }}>{sale.id}</p>

                                                                    <img
                                                                        src={appTheme.dark ? copyIconDark : copyIconLight}
                                                                        style={{ width: "16px", height: "16px" }}
                                                                        alt="Copy"
                                                                    />
                                                                </span>
                                                            </Fragment> : null}
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={`customer-sub-section-cont ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                                        style={{
                                                            margin: "10px 20px 0 20px",
                                                            width: "calc(100% - 45px)",
                                                            minHeight: `calc(100vh - ${180 + (desktopView ? 41.6 : 0)}px)`,
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                padding: "5px 15px",
                                                                fontWeight: "bold",
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            {appLanguage.en ? "History" : "Historial"}
                                                        </p>

                                                        {loadingNotes ?
                                                            <div
                                                                className="cust-account-history-spinner-cont"
                                                            >
                                                                <Spinner />
                                                            </div>
                                                            : <Fragment>

                                                                {createNewNote ?
                                                                    <div
                                                                        className="cust-account-history-notes-new-cont"
                                                                        style={{
                                                                            minHeight: `calc(100vh - ${255 + 40 - (desktopView ? 0 : 40)}px)`,
                                                                            maxHeight: `calc(100vh - ${255 + 40 - (desktopView ? 0 : 40)}px)`,
                                                                        }}
                                                                    >
                                                                        <p className="cust-account-history-notes-new-title">{appLanguage.en ? "Add note" : "Agregar nota"}</p>
                                                                        <textarea
                                                                            autoComplete="off"
                                                                            autoCorrect="off"
                                                                            style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                                                            placeholder={appLanguage.en ? "If you need it, leave comments about this sale here." : "Si lo necesitas deja comentarios sobre este venta aquí."}
                                                                            className={`cust-account-history-notes-new-textarea ${appTheme.dark ? "edit-customer-desktop-dark" : "edit-customer-desktop-light"} ${inputAlert ? "new-custmer-textarea-alert" : (appTheme.dark ? "cust-account-history-textarea-border-dark" : "cust-account-history-textarea-border-light")}`}
                                                                            id={"Add note"}
                                                                            name={"Add note"}
                                                                            maxLength={500}
                                                                            onChange={e => onChange(e)}
                                                                            value={noteData}
                                                                            onKeyDown={e => handleKeyPress(e)}
                                                                        />
                                                                        <div className="new-customer-bottons-container2">
                                                                            <button
                                                                                onClick={e => onCancel(e)}
                                                                                className="new-customer-bottons-button-save"
                                                                            >
                                                                                <img src={crossIcon} alt="Cancel" />
                                                                                {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                                                            </button>

                                                                            <button
                                                                                onClick={e => onSave(e)}
                                                                                className="new-customer-bottons-button-save"
                                                                            >
                                                                                <img src={saveIcon} alt="Save" />
                                                                                {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    : <Fragment>

                                                                        <div
                                                                            className="cust-account-sales-rec-records"
                                                                            style={{
                                                                                minHeight: `calc(100vh - ${255 + 40 - (desktopView ? 0 : 40)}px)`,
                                                                                maxHeight: `calc(100vh - ${255 + 40 - (desktopView ? 0 : 40)}px)`,
                                                                            }}
                                                                        >
                                                                            {sale.history && sale.history.length > 0 ? <Fragment>
                                                                                <div style={{ height: "10px" }} ref={setTopReference} />
                                                                                {sale.history.sort((a, b) => b.time - a.time).map((note, index) => {
                                                                                    return (
                                                                                        <div key={index} className="cust-account-history-notes-item">
                                                                                            <div className="cust-account-history-notes-element-date-and-delete">
                                                                                                <p style={appTheme.dark ? { color: "#74b7ff" } : { color: "#1083ff" }} className="cust-account-history-notes-element-date">{getTime(note.time)} - {getDate(note.time)} - {note.author}</p>
                                                                                                {userData ? ((userData.email + "*") === note.author ?
                                                                                                    <div onClick={() => onDeleteNote(note.id)} className="cust-account-history-notes-element-delete" >
                                                                                                        <img
                                                                                                            onClick={() => onDeleteNote(note.id)}
                                                                                                            src={appTheme.dark ? closeDarkIcon : closeLightIcon}
                                                                                                            alt="delete"
                                                                                                        />
                                                                                                    </div>
                                                                                                    : null) : null}
                                                                                            </div>
                                                                                            <p className="cust-account-history-notes-element-content">{appLanguage.en ? note.noteDesc.enNote : note.noteDesc.esNote}</p>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </Fragment> :
                                                                                <div className="no-products-yet-main-container">
                                                                                    <img className="no-products-yet-main-img" src={emptyFolderImg} alt="No products yet" />
                                                                                    <p><b>{appLanguage.en ? "No notes found" : "Ninguna nota encontrada"}</b></p>
                                                                                    <p>{appLanguage.en ? "Any changes to this sale will be displayed here." : "Cualquier cambio en esta venta se mostrará aquí."}</p>
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        <div className="cust-account-history-con-buttons">
                                                                            <button onClick={onClickAdd} className="cust-account-history-con-buttons-elem"><p>{appLanguage.en ? "Add note" : "Agregar nota"}</p></button>
                                                                            {onTop ? null :
                                                                                <button
                                                                                    onClick={handleClickTop}
                                                                                    className="cust-account-history-con-buttons-elem"
                                                                                    style={{ minWidth: "60px" }}
                                                                                >
                                                                                    <img src={upDarkIcon} alt="Go Up" />
                                                                                </button>
                                                                            }
                                                                        </div>

                                                                    </Fragment>}
                                                            </Fragment>}
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                        : null)
                                }
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default SaleHistoryPage;