import "./getQuoteComponent.scss";
import React, { useContext, useEffect, useRef, useState } from "react";

import ReactToPrint from 'react-to-print';
import jsPDF from 'jspdf';

import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";

import printIconDark from "../../../icons/printer-dark.png";
import downloadIconDark from "../../../icons/download-dark.png";
import QuotationReceiptPDF from "../../../receipts/quotationReceiptPDF";
import QuotationReceipt from "../../../receipts/quotationReceipt";
import { SoftLoadingContext } from "../../../components/soft-loading/softLoadingContext";

const GetQuoteComponent = ({ setSaleMoreOptionsActive }) => {
    const { shoppingCart, invoiceTemplate } = useContext(AuthContext);
    const { isTouchEnabled, appLanguage } = useContext(AppContext);
    const { setSoftLoadingActive } = useContext(SoftLoadingContext);

    const [receiptData, setReceiptData] = useState(shoppingCart && invoiceTemplate ? {
        ...shoppingCart,
        ...invoiceTemplate,
        footer: "Esto no es una factura. El precio y la disponibilidad de los artículos pueden variar con el tiempo.",
        time: (new Date()).getTime(),
    } : null);

    useEffect(() => {
        if (shoppingCart && invoiceTemplate) {
            setReceiptData({
                ...shoppingCart,
                ...invoiceTemplate,
                footer: "Esto no es una factura. El precio y la disponibilidad de los artículos pueden variar con el tiempo.",
                time: (new Date()).getTime(),
            })
        } else {
            setReceiptData(null);
        }
        // eslint-disable-next-line
    }, [shoppingCart, invoiceTemplate]);

    const quotationRef = useRef();
    const quotationRefPDF = useRef();

    const generatePDF = async () => {
        blinkSoftLoading();
        setSaleMoreOptionsActive(false);
        try {
            const doc = new jsPDF('portrait', 'pt', 'letter');
            doc.html(quotationRefPDF.current, {
                callback: function (pdf) {
                    pdf.save(`Cotizacion.pdf`);
                },
                x: 65,
                y: 0,
                html2canvas: {
                    scale: 0.74
                }
            });
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const blinkSoftLoading = () => {
        setSoftLoadingActive(true);
        setTimeout(() => {
            setSoftLoadingActive(false);
        }, 1000);
    }

    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                height: "150px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            {receiptData && (
                <ReactToPrint
                    trigger={() =>
                        <button
                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                            style={{
                                height: "45px",
                                justifyContent: "flex-start",
                                minWidth: "205px",
                                margin: "20px"
                            }}
                        >
                            <img
                                style={{
                                    marginLeft: "10px",
                                    width: "20px",
                                    height: "20px"
                                }}
                                src={printIconDark}
                                alt="Print"
                            />
                            <p>Imprimir cotización</p>
                        </button>
                    }
                    content={() => quotationRef.current}
                    documentTitle={`Cotizacion`}
                />
            )}

            <div style={{ display: 'none' }}>
                <QuotationReceipt receiptData={receiptData} receiptRef={quotationRef} />
            </div>

            <button
                onClick={generatePDF}
                className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                style={{
                    height: "45px",
                    justifyContent: "flex-start",
                    minWidth: "205px",
                    margin: "20px"
                }}
            >
                <img
                    style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "10px",
                        marginLeft: "18px"
                    }}
                    src={downloadIconDark}
                    alt="Download"
                />
                <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "Download PDF" : "Descargar PDF"} </p>
            </button>

            <QuotationReceiptPDF receiptData={receiptData} receiptRef={quotationRefPDF} />
        </div>
    );
}

export default GetQuoteComponent;