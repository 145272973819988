import { Routes, Route, Navigate } from 'react-router-dom';
import { useEffect } from 'react';

import AppProvider from './context/AppContext';
import SideMenuProvider from './context/SideMenuContext';
import AlertProvider from './components/alert/alertContext';
import AlertNewAuthUserProvider from './components/alertNewAuthUser/alertNewAuthUserContext';
import SoftAlertProvider from './components/soft-alert/softAlertContext';
import SoftLoadingProvider from './components/soft-loading/softLoadingContext';

import InputPickerDateProvider from './appTools/appToolsComponents/inputPickerDate/inputPickerDateContext';
import MovementDetailsProvider from './appTools/appToolsComponents/movementDetails/movementDetailsContext';
import StartAndEndShiftProvider from './appTools/appToolsComponents/startAndEndShift/startAndEndShiftContext';

import CollectPaymentProvider from './appTools/appToolsComponents/collectPayment/collectPaymentContext';
import ViewPaymentDetailsProvider from './appTools/appToolsComponents/viewPaymentDetails/viewPaymentDetailsContext';
import ShareViaExternalAppProvider from './appTools/appToolsComponents/shareViaExternalApp/shareViaExternalAppContext';
import SaleMoreOptionsProvider from './appTools/appToolsComponents/saleMoreOptions/saleMoreOptionsContext';

import AuthProvider from './firebase/context';

import InsidePage from './routes/inside/inside.page';

import ChooseAccountPage from './routes/inside/chooseAccount.page';
import ConnectWithExistingAccount from './routes/inside/connectWithExistingAccount';
import CreateBusinessAccountPage from './routes/inside/createBusinessAccount.page';
import PricingOptionsPage from './routes/inside/pricingOptions.page';

import HomePage from './routes/inside/home.page';
import BusinessAccountPage from './routes/inside/businessAccount.page';
import EdictAccountPage from './routes/inside/businessAccount.subPages/edictAccount.page';
import DeleteAccountPage from './routes/inside/businessAccount.subPages/edictAccount.subPages/deleteAccount.page';
import AuthorizedUsersPage from './routes/inside/businessAccount.subPages/authorizedUsers.page';
import LeaveAccountPage from './routes/inside/businessAccount.subPages/leaveAccount';

// SubPages of AuthorizedProfilesPage
import AuthorizeAccessPage from './routes/inside/businessAccount.subPages/authorizedProfiles.subPages/authorizeAccess.page';
import ViewAuthorizedProfilePage from './routes/inside/businessAccount.subPages/authorizedProfiles.subPages/viewAuthorizedProfile.page';

import SubscriptionPage from './routes/inside/businessAccount.subPages/subscription.page';

import AccountActivityPage from './routes/inside/businessAccount.subPages/accountActivity';

// SubPages of SubscriptionPage
import PlanDetailsPage from './routes/inside/businessAccount.subPages/subscription.subPages/planDetails.page';
import CancelSubscriptionPage from './routes/inside/businessAccount.subPages/subscription.subPages/cancelSubscription.page';

import BillsPage from './routes/inside/businessAccount.subPages/bills.page';

// SubPages of BillsPage
import TakePaymentPage from './routes/inside/businessAccount.subPages/bills.subPages/takePayment.page';
import BillingHistoryPage from './routes/inside/businessAccount.subPages/bills.subPages/billingHistory.page';

import SettingsPage from './routes/inside/settings.page';

import ChangeLanguagePage from './routes/inside/settings.subPages/changeLanguage.page';
import PrintSizePage from './routes/inside/settings.subPages/printSize.page';
import ProtectAccessPage from './routes/inside/settings.subPages/protectAccess.page';
import TermsAndCondPage from './routes/inside/settings.subPages/termsAndCond.page';
import PrivacyPoliciesPage from './routes/inside/settings.subPages/privacyPolicies.page';

import HelpPage from './routes/inside/help.page';
import MyProfilePage from './routes/inside/myProfile.page';

import NotificationsPage from "./routes/inside/notifications.page";

import EdictProfilePage from './routes/inside/myProfile.subPages/edictProfile.page';
import ChangePasswordPage from './routes/inside/myProfile.subPages/changePassword.page';
import SecurityPage from './routes/inside/myProfile.subPages/security.page';

import DashboardPage from './routes/inside/dashboard.page';
import CustomersPage from './routes/inside/customers.page';
import SuppliersPage from './routes/inside/suppliers.page';
import SearcherPage from './routes/inside/searcher.page';

import OutSidePage from './routes/outside/outside.page';

import LogInPage from './routes/outside/login.page';
import NewAccountPage from './routes/outside/newAccount.page';
import ForgotPassword from './routes/outside/forgotPassword.page';
import CompleteDataPage from './routes/outside/completeData.page';
import CheckingPage from './routes/outside/checking.page';

// Tools subPages
import CreateCustomerPage from './routes/inside/customers.subPages/createCustomer.page';
import CustomerViewPage from './routes/inside/customers.subPages/customerView.page';

import ProductsPage from './routes/inside/products.subPages/products.page';
import CreateProductPage from './routes/inside/products.subPages/createProduct.page';

import InventoryPage from './routes/inside/inventory.subPages/inventory.page';

import SalesPage from './routes/inside/sales.subPages/sales.page';
import SaleViwePage from './routes/inside/sales.subPages/saleView.page';

import './aztoniaFramework.scss';
import './App.scss';

import VerifyEmailPage from './routes/outside/verifyEmail.page';
import NoFoundPage from './routes/outside/noFound.page';

import PrivateRoutes from './utils/PrivateRoutes';
import IsEmailVerify from './utils/IsEmailVerify';
import IsAccountSelected from './utils/isAccountSelected';
import IsAppLock from './utils/isAppLock';
import UnverifiedUserRoutes from './utils/UnverifiedUserRoutes';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import CustomerDetailsPage from './routes/inside/customers.subPages/customerDetails.page';
import ProductViewPage from './routes/inside/products.subPages/productView.page';

import AddingProductsPage from './routes/inside/inventory.subPages/addingProducts.page';
import AddingCustomersPage from './routes/inside/customers.subPages/addingCustomers.page';
import UploadingProductsPage from './routes/inside/inventory.subPages/uploadingProducts.page';

import EditingProductsPage from './routes/inside/inventory.subPages/editingProducts.page';
import EditingCustomersPage from './routes/inside/customers.subPages/editingCustomers.page';
import UploadingCustomersPage from './routes/inside/customers.subPages/uploadingCustomers.page';

import CreateSupplierPage from './routes/inside/suppliers.subPages/createSupplier.page';
import AddingSuppliersPage from './routes/inside/suppliers.subPages/addingSuppliers.page';
import EditingSuppliersPage from './routes/inside/suppliers.subPages/editingSuppliers.page';
import UploadingSuppliersPage from './routes/inside/suppliers.subPages/uploadingSuppliers.page';
import SupplierViewPage from './routes/inside/suppliers.subPages/supplierView.page';
import SupplierDetailsPage from './routes/inside/suppliers.subPages/supplierDetails.page';
import ProductDetailsPage from './routes/inside/inventory.subPages/productDetails.page';
import ProductAdvancedPage from './routes/inside/inventory.subPages/productAdvanced.page';
import ProductMovementsPage from './routes/inside/inventory.subPages/productMovements.page';
import ProductIndicatorsPage from './routes/inside/inventory.subPages/productIndicators.page';
import RegisterMovementPage from './routes/inside/inventory.subPages/registerMovement.page';

import ManageShiftPage from './routes/inside/sales.subPages/manageShift.page';
import ShoppingCartPage from './routes/inside/sales.subPages/shoppingCart.page';
import SalesSummaryPage from './routes/inside/sales.subPages/salesSummary.page';
import SuccessfulSalePage from './routes/inside/sales.subPages/successfulSale.page';
import SalePaymentsPage from './routes/inside/sales.subPages/salePayments.page';
import SaleMoreDetailsPage from './routes/inside/sales.subPages/saleMoreDetails.page';
import SaleHistoryPage from './routes/inside/sales.subPages/saleHistory.page';
import InvoicesPage from './routes/inside/invoices.Pages/invoices.page';
import ReceiptTemplatePage from './routes/inside/businessAccount.subPages/receiptTemplate.page';

import StructureProvider from './components/structure/structureContext';
import LabelOptionsProvider from './appTools/appToolsComponents/labelOptions/labelOptionsContext';

const App = () => {
  useEffect(() => {
    const preventDefaults = (e) => {
      if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA' && e.target.tagName !== 'SELECT') {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    // Adding event listeners for drag and drop events
    window.addEventListener('dragenter', preventDefaults);
    window.addEventListener('dragover', preventDefaults);
    window.addEventListener('dragleave', preventDefaults);
    window.addEventListener('drop', preventDefaults);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('dragenter', preventDefaults);
      window.removeEventListener('dragover', preventDefaults);
      window.removeEventListener('dragleave', preventDefaults);
      window.removeEventListener('drop', preventDefaults);
    };
  }, []);

  return (
    <HelmetProvider>
      <Helmet >
        <meta name="theme-color" content={`#627890`} />
      </Helmet>
      <AppProvider>
        <SideMenuProvider>
          <AuthProvider>
            <SoftLoadingProvider>
              <AlertProvider>
                <StructureProvider>
                  <AlertNewAuthUserProvider>
                    <InputPickerDateProvider>
                      <StartAndEndShiftProvider>
                        <CollectPaymentProvider>
                          <ShareViaExternalAppProvider>
                            <ViewPaymentDetailsProvider>
                              <LabelOptionsProvider>
                                <SaleMoreOptionsProvider>
                                  <MovementDetailsProvider>
                                    <SoftAlertProvider>
                                      <Routes>
                                        <Route path='/404' element={<NoFoundPage />} />
                                        <Route path='*' element={<Navigate replace to='/404' />} />

                                        {/*If the user is verified*/}
                                        <Route element={<PrivateRoutes />} >

                                          {/** To be able to use the app the email has to be verify */}
                                          <Route element={<IsEmailVerify />}>

                                            <Route path='/' element={<InsidePage />}>

                                              {/*To procted the app content*/}
                                              <Route element={<IsAppLock />}>

                                                {/** Account must be choosend */}
                                                <Route element={<IsAccountSelected />}>

                                                  <Route index element={<HomePage />} />
                                                  <Route path='/cuenta-de-negocios' element={<BusinessAccountPage />} />

                                                  {/*Business Sittings Subpages*/}
                                                  <Route path='/editar-cuenta' element={<EdictAccountPage />} />
                                                  <Route path='/eliminar-cuenta' element={<DeleteAccountPage />} />
                                                  <Route path='/usuarios-autorizados' element={<AuthorizedUsersPage />} />
                                                  <Route path='/abandonar-cuenta' element={<LeaveAccountPage />} />
                                                  <Route path='/plantilla-de-recibos' element={<ReceiptTemplatePage />} />

                                                  {/** AuthorizedProfilesPage subpages */}
                                                  <Route path='/autorizar-acceso' element={<AuthorizeAccessPage />} />
                                                  <Route path='/ver-perfl-usuario' element={<ViewAuthorizedProfilePage />} />

                                                  <Route path='/suscripcion' element={<SubscriptionPage />} />
                                                  <Route path='/actividad-de-la-cuenta' element={<AccountActivityPage />} />

                                                  {/** SubscriptionPage subpages */}
                                                  <Route path='/cancelar-suscripcion' element={<CancelSubscriptionPage />} />
                                                  <Route path='/detalles-del-plan' element={<PlanDetailsPage />} />

                                                  <Route path='/facturas' element={<BillsPage />} />

                                                  {/** BillsPage subpages */}
                                                  <Route path='/realizar-pago' element={<TakePaymentPage />} />
                                                  <Route path='/historial-de-facturacion' element={<BillingHistoryPage />} />

                                                  <Route path='/metricas' element={<DashboardPage />} />

                                                  <Route path='/clientes' element={<CustomersPage />} />
                                                  <Route path='/proveedores' element={<SuppliersPage />} />

                                                  <Route path='/buscador' element={<SearcherPage />} />

                                                  {/** Customer subPages */}
                                                  <Route path='/agregar-cliente' element={<CreateCustomerPage />} />
                                                  <Route path='/agregar-clientes' element={<AddingCustomersPage />} />
                                                  <Route path='/editar-clientes' element={<EditingCustomersPage />} />
                                                  <Route path='/importar-clientes-desde-excel' element={<UploadingCustomersPage />} />
                                                  <Route path='/ver-cliente' element={<CustomerViewPage />} />
                                                  <Route path='/mas-detalles' element={<CustomerDetailsPage />} />

                                                  {/** Proveedores subPage */}
                                                  <Route path='/agregar-proveedor' element={<CreateSupplierPage />} />
                                                  <Route path='/agregar-proveedores' element={<AddingSuppliersPage />} />
                                                  <Route path='/editar-proveedores' element={<EditingSuppliersPage />} />
                                                  <Route path='/importar-proveedores-desde-excel' element={<UploadingSuppliersPage />} />
                                                  <Route path='/ver-proveedor' element={<SupplierViewPage />} />
                                                  <Route path='/proveedor-mas-detalles' element={<SupplierDetailsPage />} />

                                                  {/** Products Page */}
                                                  <Route path='/productos' element={<ProductsPage />} />
                                                  <Route path='/agregar-producto' element={<CreateProductPage />} />
                                                  <Route path='/ver-producto' element={<ProductViewPage />} />

                                                  {/** Inventory subPages */}
                                                  <Route path='/inventario' element={<InventoryPage />} />
                                                  <Route path='/agregar-productos' element={<AddingProductsPage />} />
                                                  <Route path='/editar-productos' element={<EditingProductsPage />} />
                                                  <Route path='/importar-productos-desde-excel' element={<UploadingProductsPage />} />
                                                  <Route path='/detalles-de-producto' element={<ProductDetailsPage />} />
                                                  <Route path='/opciones-avanzadas' element={<ProductAdvancedPage />} />
                                                  <Route path='/movimientos-del-producto' element={<ProductMovementsPage />} />
                                                  <Route path='/indicadores-de-desempeno-del-producto' element={<ProductIndicatorsPage />} />
                                                  <Route path='/registrar-movimiento' element={<RegisterMovementPage />} />

                                                  {/** Sales page */}
                                                  <Route path='/ventas' element={<SalesPage />} />
                                                  <Route path='/ver-detalles-de-venta' element={<SaleViwePage />} />
                                                  <Route path='/gestionar-turnos' element={<ManageShiftPage />} />
                                                  <Route path='/carrito-de-compras' element={<ShoppingCartPage />} />
                                                  <Route path='/resumen-de-venta' element={<SalesSummaryPage />} />
                                                  <Route path='/venta-exitosa' element={<SuccessfulSalePage />} />
                                                  <Route path='/pagos-a-venta' element={<SalePaymentsPage />} />
                                                  <Route path='/mas-detalles-de-venta' element={<SaleMoreDetailsPage />} />
                                                  <Route path='/historial-de-la-venta' element={<SaleHistoryPage />} />

                                                  {/** Invoices page */}
                                                  <Route path='/facturas-de-clientes' element={<InvoicesPage />} />
                                                </Route>

                                                {/** No need to choose account*/}
                                                <Route path='/elegir-cuenta' element={<ChooseAccountPage />} />
                                                <Route path='/precios-disponibles' element={<PricingOptionsPage />} />
                                                <Route path='/conectarse-con-cuenta-existente' element={<ConnectWithExistingAccount />} />
                                                <Route path='/crear-cuenta-de-negocio' element={<CreateBusinessAccountPage />} />

                                                <Route path='/ajustes' element={<SettingsPage />} />

                                                {/*App Sittings Subpages*/}
                                                <Route path='/cambiar-idioma' element={<ChangeLanguagePage />} />
                                                <Route path='/tamano-de-impresion' element={<PrintSizePage />} />
                                                <Route path='/proteger-acceso' element={<ProtectAccessPage />} />
                                                <Route path='/terminos-y-condiciones' element={<TermsAndCondPage />} />
                                                <Route path='/politicas-de-privacidad' element={<PrivacyPoliciesPage />} />

                                                <Route path='/ayuda' element={<HelpPage />} />
                                                <Route path='/mi-perfil' element={<MyProfilePage />} />
                                                <Route path='/notificaciones' element={<NotificationsPage />} />

                                                {/*Profile Subpages*/}
                                                <Route path='/editar-perfil' element={<EdictProfilePage />} />
                                                <Route path='/cambiar-password' element={<ChangePasswordPage />} />
                                                <Route path='/seguridad' element={<SecurityPage />} />
                                              </Route>

                                            </Route>
                                          </Route>
                                        </Route>

                                        <Route path='/auth' element={<OutSidePage />}>
                                          {/*If the user is verified they can not login or create account, 
                                for that they has to logout*/}
                                          <Route element={<UnverifiedUserRoutes />}>
                                            <Route path='iniciar-sesion' element={<LogInPage />} />
                                            <Route path='crear-perfil' element={<NewAccountPage />} />
                                          </Route>

                                          {/** User need to be verify to be able to use these page */}
                                          <Route element={<PrivateRoutes />} >
                                            <Route path='verificar-correo' element={<VerifyEmailPage />} />
                                            <Route path='completar-datos' element={<CompleteDataPage />} />
                                          </Route>

                                          {/** Any user can use these pages */}
                                          <Route path='has-olvidado-tu-password' element={<ForgotPassword />} />
                                          <Route path='checking-data' element={<CheckingPage />} />
                                        </Route>
                                      </Routes>
                                    </SoftAlertProvider>
                                  </MovementDetailsProvider>
                                </SaleMoreOptionsProvider>
                              </LabelOptionsProvider>
                            </ViewPaymentDetailsProvider>
                          </ShareViaExternalAppProvider>
                        </CollectPaymentProvider>
                      </StartAndEndShiftProvider>
                    </InputPickerDateProvider>
                  </AlertNewAuthUserProvider>
                </StructureProvider>
              </AlertProvider>
            </SoftLoadingProvider>
          </AuthProvider>
        </SideMenuProvider>
      </AppProvider>
    </HelmetProvider>
  );
}

export default App;