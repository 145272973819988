import React, { Fragment, useContext, useEffect, useRef, useState } from "react";

import { uid } from "uid";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

import firebase from "../../../firebase";
import { onSnapshot, doc } from "firebase/firestore";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import copyIconDark from "../../../icons/copy.png";
import copyIconLight from "../../../icons/copyLight.png";

import upDarkIcon from "../../../icons/up-dark.png";

import emptyFolderImg from "../../../img/empty-folder.svg";

import completedIcon from "../../../icons/sale-completed.png";
import pendingIcon from "../../../icons/sale-pending.png";
import cancelIcon from "../../../icons/sale-cancel.png";

import ConnectionError from "../../../components/connection-error/connectionError";

import PaymentSaleMiniCard from "../../../appTools/appToolsComponents/paymentSaleMiniCard/paymentSaleMiniCard";
import ViewPaymentDetails from "../../../appTools/appToolsComponents/viewPaymentDetails/viewPaymentDetails";
import { ViewPaymentDetailsContext } from "../../../appTools/appToolsComponents/viewPaymentDetails/viewPaymentDetailsContext";
import { AlertContext } from "../../../components/alert/alertContext";

const SalePaymentsPage = () => {
    const { appTheme, appLanguage, isTouchEnabled } = useContext(AppContext);
    const { accountData, accessTo } = useContext(AuthContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setViewPaymentDetailsActive, setPaymentRef, setLoading } = useContext(ViewPaymentDetailsContext);

    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const location = useLocation();

    const [saleId, setSaleId] = useState(null);

    const [request, setRequest] = useState("0000");

    const [showConnectionError, setShowConnectionError] = useState(false);

    const [sale, setSale] = useState(null);

    // Now I have to listeng to the product doc
    useEffect(() => {
        if (saleId && (request !== "0000")) {
            setShowConnectionError(false);
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/sales`, saleId.id), (doc) => {
                const saleData = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete saleData.searchKeys;

                if (doc.data()) {
                    setSale(saleData);
                    setShowConnectionError(false);
                } else {
                    setShowConnectionError(true);
                }

                // Si el producto no exite entonce no existe
                setLoading(false);
            }, err => {
                console.error(err);
                setShowConnectionError(true);
                setLoading(false);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [request]);

    const onTryAgain = () => {
        setRequest(uid());
    }

    const navigate = useNavigate();

    useEffect(() => {
        if (location.state) {
            setSaleId({
                "id": location.state.id
            });
            setRequest(uid());
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.sales)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        navigate(null, { replace: true, state: saleId });
        // eslint-disable-next-line
    }, [saleId]);

    const getSaleIcon = (status) => {
        switch (status) {
            case "completed":
                return completedIcon;
            case "canceled":
                return cancelIcon;
            default:
                return pendingIcon;
        }
    }

    const getSaleStatus = (status) => {
        switch (status) {
            case "completed":
                return appLanguage.en ? "Completed" : "Completa";
            case "canceled":
                return appLanguage.en ? "Canceled" : "Cancelada";
            default:
                return appLanguage.en ? "Pending" : "Pendiente";
        }
    }

    const copySaleId = () => {
        try {
            const text = sale.id;
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The code has been copied',
                    es: 'El código ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    let observedRef = useRef(null);

    const [width, setWidth] = useState();

    const [onTop, setOnTop] = useState(true);
    const [topRef, setTopRef] = useState(null);

    const setTopReference = (inputElement) => {
        setTopRef(inputElement);
    }

    const handleClickTop = () => {
        if (topRef) {
            topRef.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const toObserver = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setOnTop(true);
                } else {
                    setOnTop(false);
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            }
        );

        if (topRef) {
            toObserver.observe(topRef);
        }

        return () => {
            if (topRef) {
                // eslint-disable-next-line
                toObserver.unobserve(topRef);
            }
        };
    }, [topRef]);

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension, isSideMenuMinimized]);

    const onClickPayment = (ref) => {
        setViewPaymentDetailsActive(true);
        setPaymentRef(ref);
    }

    const cancelPayment = async (paymentRef) => {
        setLoading(true);
        setAlertActive(false);

        try {
            const res = await firebase.useCancelSalePayment({
                "businessId": accountData.id,
                "saleId": saleId.id,
                "paymentRef": paymentRef
            });

            setSale(res)
            setLoading(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The payment has been successfully cancelled.',
                    es: 'El paga ha sido cancelada de manera exitosa.',
                }
            });
        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to cancel this payment.`,
                    es: `Error al intentar cancelar este pago.`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Payments on sale" : "Pagos a venta"}</title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`} ref={observedRef}>
                            {accessTo.sales ? <Fragment>
                                <ViewPaymentDetails
                                    pickerWidth={width}
                                    sale={sale}
                                    cancelPayment={cancelPayment}
                                />

                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Payments on sale" : "Pagos a venta"}
                                    />
                                : null}

                                {(showConnectionError ? <ConnectionError onTryAgain={onTryAgain} /> :
                                    (sale ?
                                        <Fragment>
                                            <div style={{ marginTop: "25px", display: "flex", width: "100%", justifyContent: "center" }}>
                                                {/** Main container for seccion maxWidth: "745px" */}
                                                <div style={{ width: "100%", maxWidth: "740px" }}>
                                                    {/** Sale heather */}
                                                    <div className="unselectable" style={{ display: "flex", width: "100%", marginBottom: "20px" }}>
                                                        <div
                                                            className={`shoppo-cart-cust-card-div ${appTheme.dark ? "shoppo-cart-cust-card-div-dark" : "shoppo-cart-cust-card-div-light"}`}
                                                            style={{
                                                                display: "flex",
                                                                paddingLeft: "10px",
                                                                borderRadius: "100px",
                                                                margin: "0px 20px 0 20px",
                                                                width: "calc(100% - 40px)",
                                                                height: "40px",
                                                                minHeight: "36px",
                                                            }}
                                                        >
                                                            {sale ? <Fragment>
                                                                <img
                                                                    style={{
                                                                        width: "25px",
                                                                        height: "25px",
                                                                        marginLeft: "0px",
                                                                    }}
                                                                    src={getSaleIcon(sale.status)}
                                                                    alt="Status"
                                                                />

                                                                <p style={{ whiteSpace: "nowrap", fontSize: "16px", fontWeight: 500, marginLeft: "15px" }}>
                                                                    {getSaleStatus(sale.status)}
                                                                </p>

                                                                <span
                                                                    onClick={copySaleId}
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "6px 10px",
                                                                        marginRight: "5px"
                                                                    }}
                                                                    className={`sale-copy-id-successful ${isTouchEnabled() ? "sale-copy-id-successful-no-hover" : "sale-copy-id-successful-hover"}`}
                                                                >
                                                                    <p style={{ marginRight: "10px" }}>{sale.id}</p>

                                                                    <img
                                                                        src={appTheme.dark ? copyIconDark : copyIconLight}
                                                                        style={{ width: "16px", height: "16px" }}
                                                                        alt="Copy"
                                                                    />
                                                                </span>
                                                            </Fragment> : null}
                                                        </div>
                                                    </div>

                                                    {sale.payments.length > 0 ?
                                                        <div
                                                            className={`customer-sub-section-cont ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                                            style={{
                                                                margin: "10px 20px 0 20px",
                                                                width: "calc(100% - 45px)",
                                                                minHeight: `calc(100vh - ${180 + (desktopView ? 41.6 : 0)}px)`,
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    padding: "5px 15px",
                                                                    fontWeight: "bold",
                                                                    fontSize: "14px",
                                                                }}
                                                            >
                                                                {appLanguage.en ? "Payments" : "Pagos"}
                                                            </p>

                                                            <div
                                                                className="cust-account-sales-rec-records"
                                                                style={{
                                                                    minHeight: `calc(100vh - ${255 + (onTop ? 0 : 40) - (desktopView ? 0 : 40)}px)`,
                                                                    maxHeight: `calc(100vh - ${255 + (onTop ? 0 : 40) - (desktopView ? 0 : 40)}px)`,
                                                                }}
                                                            >
                                                                <div style={{ height: "10px" }} ref={setTopReference} />

                                                                {sale.payments.sort((a, b) => b.time - a.time).map((payment, index) => {
                                                                    return (
                                                                        <PaymentSaleMiniCard
                                                                            key={index}
                                                                            payment={payment}
                                                                            onClickLink={onClickPayment}
                                                                            width={width}
                                                                            saleStatus={sale ? sale.status : "canceled"}
                                                                        />
                                                                    );
                                                                })}
                                                            </div>

                                                            <div className="cust-account-history-con-buttons">
                                                                {onTop ? null :
                                                                    <button
                                                                        onClick={handleClickTop}
                                                                        className="cust-account-history-con-buttons-elem"
                                                                        style={{ minWidth: "60px" }}
                                                                    >
                                                                        <img src={upDarkIcon} alt="Go Up" />
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="no-sales-rec-yet-main-container">
                                                            <img
                                                                className="no-sales-rec-yet-main-img"
                                                                src={emptyFolderImg}
                                                                alt="No payments yet"
                                                            />
                                                            <p><b>{appLanguage.en ? "No payment processed" : "Ningún pago procesado"}</b></p>
                                                            <p style={{ padding: "10px 20px" }}>{appLanguage.en ? "No payment has been made for this sale so far." : "Hasta el momento no se ha realizado ningún pago para esta venta."}</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </Fragment>
                                        : null)
                                )}
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default SalePaymentsPage;