import { Fragment, useContext, useEffect, useState } from "react";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { AppContext } from "../../../context/AppContext";

import Spinner from "../../../components/spinner/spinner";
import PageNavigator from "../pageNavigator/pageNavigator";
import ConnectionError from "../../../components/connection-error/connectionError";

import completedIcon from "../../../icons/sale-completed.png";
import pendingIcon from "../../../icons/sale-pending.png";
import cancelIcon from "../../../icons/sale-cancel.png";

import emptySearch from "../../../img/empty-search.svg";
import emptyBox from "../../../img/empty-box.svg";
import { AuthContext } from "../../../firebase/context";

const SalesNewTable = ({
    onScroll,
    headerHeight,
    loading,
    getAuthUserName,
    showConnectionError,
    tryAgain,
    results,
    filter,
    onClickLink,
    activeId,
    setActiveReference,
    pageNumber,
    navControlers,
    showPageNav,
    onClickNext,
    onClickPrevious,
    dropDownMenuOpen,
    dropDownSettingsOpen,
    empty
}) => {

    const { 
        appTheme, 
        appLanguage, 
        formatRationalNumber, 
        dropDownCartOpen, 
        dropDownProfileMenuOpen, 
        isTouchEnabled, 
        getTimeInString, 
        getDateWithMonthShort,
    } = useContext(AppContext);

    const { salesStatistics } = useContext(AuthContext);

    const { desktopView } = useContext(SideMenuContext);

    const [tableHeight, setTableHeight] = useState("calc(100vh - 320px)");

    useEffect(() => {
        if (headerHeight) {
            setTableHeight(`calc(100vh - ${ headerHeight + 90}px)`)
        }
    }, [headerHeight, desktopView]);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownMenuOpen || dropDownSettingsOpen || dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownMenuOpen, dropDownSettingsOpen, dropDownCartOpen, dropDownProfileMenuOpen]);

    const getSaleIcon = (status) => {
        switch (status) {
            case "completed":
                return completedIcon;
            case "canceled":
                return cancelIcon;
            default:
                return pendingIcon;
        }
    }

    const getPendingAmount = (transaccion) => {
        if (transaccion.status === "pending") {
            if (Array.isArray(transaccion.payments)) {
                let tempPendingAmount = transaccion.total;
                transaccion.payments.forEach((payment) => {
                    if (payment.status !== "canceled") {
                        tempPendingAmount -= payment.cashAmount;
                    }
                });
                return Number(tempPendingAmount.toFixed(2));
            } else {
                return Number(transaccion.total.toFixed(2));
            }
        } else {
            return 0;
        }
    }

    const borderDark = "2px solid rgba(255, 255, 255, 0.075)";
    const borderLight = "2px solid rgba(0, 0, 0, 0.075)";

    const border2Dark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Light = "2px solid rgba(0, 0, 0, 0.075)";

    const widthCheck = "20px";
    const widthCode = "105px";
    const widthTotal = "130px";
    const widthPending = "130px";
    const widthHour = "100px";
    const widthDate = "130px";
    const widthUser = "170px";
    const widthItems = "350px";

    return (
        <div
            className={`inventory-table-container unselectable ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
            style={{
                maxHeight: tableHeight,
                height: tableHeight,
                colorScheme: appTheme.dark ? "dark" : "light", 
                borderRadius: "10px"
            }}
        >
            <div onScroll={onScroll} className="inventory-table-section-1" style={{overflow: loading ? "hidden" : "auto"}}>
                {loading ? 
                    <div style={{ marginTop: "50px", display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                        <Spinner />
                    </div> 
                : <Fragment>
                    {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : <Fragment>
                        {(!empty) ?
                            <table className="inventory-table-select-table">
                                <thead>
                                    <tr>
                                        <th className={`inventory-table-select-th inventory-table-select-td-sticky`} style={{ position: makeSticky ? "sticky" : "static", border: appTheme.dark ? borderDark : borderLight, minWidth: widthCheck, maxWidth: widthCheck, backgroundColor: appTheme.dark ? "#405163" : "#a1b7ce"}}>
                                            <span style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}></span> 
                                        </th>

                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthCode, maxWidth: widthCode }}>
                                            <p>{appLanguage.en ? "Code" : "Código"}</p>
                                        </th>

                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthTotal, maxWidth: widthTotal }}>
                                            <p>{appLanguage.en ? "Total" : "Total"}</p>
                                        </th>

                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthPending, maxWidth: widthPending }}>
                                            <p>{appLanguage.en ? "Pending" : "Pendiente"}</p>
                                        </th>

                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthHour, maxWidth: widthHour }}>
                                            <p>{appLanguage.en ? "Hour" : "Hora"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthDate, maxWidth: widthDate }}>
                                            <p>{appLanguage.en ? "Date" : "Fecha"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthUser, maxWidth: widthUser }}>
                                            <p>{appLanguage.en ? "Seller" : "Vendedor"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthItems, maxWidth: widthItems, borderTopRightRadius: "10px" }}>
                                            <p>{appLanguage.en ? "Items" : "Artículos"}</p>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {results ?
                                        results.map((transaccion) => {
                                            return (
                                                <tr 
                                                    className={appTheme.dark ? (isTouchEnabled() ? "inventory-table-select-tr-dark-no-hover " : "inventory-table-select-tr-dark-hover") : (isTouchEnabled() ? "inventory-table-select-tr-light-no-hover" : "inventory-table-select-tr-light-hover")} 
                                                    ref={transaccion.id === activeId ? setActiveReference : null}
                                                    key={transaccion.id} 
                                                    onClick={() => onClickLink(transaccion)}
                                                    style={
                                                        (transaccion.id === activeId) ? 
                                                            {
                                                                cursor: "pointer",
                                                                backgroundColor: "#62789036",
                                                                border: "1px solid #627890a9"
                                                            } 
                                                        : { cursor: "pointer",}
                                                    } 
                                                >
                                                    <td className={`inventory-table-select-td inventory-table-select-th-sticky ${appTheme.dark ? "inventory-table-select-td-sticky-dark" : "inventory-table-select-td-sticky-light"}`} style={{ position: makeSticky ? "sticky" : "static", border: appTheme.dark ? border2Dark : border2Light, minWidth: widthCheck, maxWidth: widthCheck }}>
                                                        <span style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>
                                                            <img style={{width: "20px", height: "20px"}} src={getSaleIcon(transaccion.status)} alt="Status" />
                                                        </span>
                                                    </td>

                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthCode, maxWidth: widthCode }}>
                                                        <p style={{whiteSpace: "nowrap"}}>{transaccion.id}</p>
                                                    </td>

                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthTotal, maxWidth: widthTotal }}>
                                                        <p style={{whiteSpace: "nowrap"}}>{formatRationalNumber(transaccion.total)}</p>
                                                    </td>

                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthPending, maxWidth: widthPending }}>
                                                        <p style={{whiteSpace: "nowrap"}}>{formatRationalNumber(getPendingAmount(transaccion))}</p>
                                                    </td>

                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthHour, maxWidth: widthHour }}>
                                                        <p style={{whiteSpace: "nowrap"}}>{getTimeInString(transaccion.time)}</p>
                                                    </td>

                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthDate, maxWidth: widthDate }}>
                                                        <p style={{whiteSpace: "nowrap"}}>{getDateWithMonthShort(new Date(transaccion.time))}</p>
                                                    </td>

                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthUser, maxWidth: widthUser }}>
                                                        <p 
                                                            style={{
                                                                "display": "-webkit-box",
                                                                "WebkitBoxOrient": "vertical",
                                                                "WebkitLineClamp": "1",
                                                                "lineClamp": "1",
                                                                "overflow": "hidden",
                                                                "textOverflow": "ellipsis"
                                                            }}
                                                        >
                                                            {transaccion.soldBy ? getAuthUserName(transaccion.soldBy) : <b>--</b>}
                                                        </p>
                                                    </td>

                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthItems, maxWidth: widthItems }}>
                                                        <p
                                                            style={{
                                                                "WebkitBoxOrient": "vertical",
                                                                "WebkitLineClamp": "1",
                                                                "lineClamp": "1",
                                                                "overflow": "hidden",
                                                                "textOverflow": "ellipsis"
                                                            }}
                                                        >
                                                            {transaccion.items.map((item, index) => {
                                                                return (
                                                                    <Fragment key={index} >(<b>{item.quantity}:</b> {item.product.name}), </Fragment>
                                                                )
                                                            })}
                                                        
                                                        </p>
                                                    </td>
                                                    
                                                </tr>
                                            );
                                        })
                                    : null}
                                </tbody>
                            </table>
                        :
                            (salesStatistics ? <Fragment>
                                <NoSalessYet filter={filter} salesStatistics={salesStatistics} />
                            </Fragment> : null)
                        }
                    </Fragment>}
                </Fragment>}
            </div>
            <div>
                {/** Aquí va la navegacion */}
                {loading || empty || showConnectionError ? null :
                    <PageNavigator
                        pageNumber={pageNumber}
                        navControlers={navControlers}
                        onChangePageNumber={null}
                        showPageNav={showPageNav}
                        onClickNext={onClickNext}
                        onClickPrevious={onClickPrevious}
                        empty={false}
                        elemMarginTo={"5px"}
                        elemmarginBottom={"5px"}
                    />
                }
            </div>
        </div>
    )
}

const NoSalessYet = ({filter, salesStatistics}) => {
    const { appLanguage } = useContext(AppContext);
    const [ noSales, setNoSales ] = useState(salesStatistics.all > 0 ? false : true);

    useEffect(() => {
        setNoSales(salesStatistics.all > 0 ? false : true);
    }, [filter, salesStatistics]);

    const [ enTitle, setEnTitle ] = useState("There are no sales yet");
    const [ esTitle, setEsTitle ] = useState("Aún no hay ventas");

    const [ enDesc, setEnDesc ] = useState("When you have completed a sale, it will be displayed in this section.");
    const [ esDesc, setEsDesc ] = useState("Cuando hayas completado alguna venta, se mostrará en esta sección.");

    useEffect(() => {
        if (noSales) {
            setEnTitle("There are no sales yet");
            setEsTitle("Aún no hay ventas");
            setEnDesc("When you have completed a sale, it will be displayed in this section.");
            setEsDesc("Cuando hayas completado alguna venta, se mostrará en esta sección.");
        } else {
            setEnTitle("We couldn't find any results for your search");
            setEsTitle("No pudimos encontrar ningún resultado para tu búsqueda");
            setEnDesc('You can search by sale reference number, code of the products sold or record number of the customer to whom the sale was made.');
            setEsDesc('Puedes buscar por número de referencia de la venta, código de los productos vendidos o número de registro del cliente a quien se le realizó la venta.');
        }
    }, [noSales])

    return (
        <div className="no-customer-yet-main-container">
            <img className="no-customer-yet-main-img" src={noSales ? emptyBox : emptySearch } alt="No customers yet" />
            <p><b>{appLanguage.en ? enTitle : esTitle}</b></p>
            <p>{appLanguage.en ? enDesc : esDesc}</p>
        </div>
    )
}

export default SalesNewTable;