import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";
import { AlertContext } from "../../../components/alert/alertContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import { uid } from "uid";

import firebase from "../../../firebase";
import { doc, getDocFromServer, getCountFromServer, collection } from "firebase/firestore";

import SettingsActionRequired from "../../../components/settings-action-required/settingsActionRequired";
import Spinner from "../../../components/spinner/spinner";
import ConnectionError from "../../../components/connection-error/connectionError";

import crossIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";

import warningIcon from "../../../img/warning.svg";

import { SideMenuContext } from "../../../context/SideMenuContext";

const CreateSupplierPage = () => {
    const { appLanguage, appTheme, formatPhoneNumberOnChange } = useContext(AppContext);
    const { accountData, accessTo } = useContext(AuthContext);
    const { desktopView } = useContext(SideMenuContext);

    const [ autoFocusName, setAutoFocusName ] = useState(true);

    const location = useLocation();
    
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const [ supplierLimits, setSupplierLimits ] = useState(null);

    const [ loading, setLoading ] = useState(true);

    const [duplicatedRNC, setDuplicatedRNC] = useState(null);

    const [ showConnectionError, setShowConnectionError ] = useState(false);

    const navigate = useNavigate();

    const inputsNames = {
        "name": uid(8),
        "phone": uid(8),
        "email": uid(8),
        "rnc": uid(8),
        "address": uid(8)
    }

    const getInputName = (uid) => {
        switch (uid) {
            case inputsNames.name:
                return "name";
            case inputsNames.phone:
                return "phone";
            case inputsNames.email:
                return "email";
            case inputsNames.rnc:
                return "rnc";
            case inputsNames.address:
                return "address";
            default:
                return uid;
        }
    } 

    const [ newSupplier, setNewSupplier ] = useState({
        "name": "",
        "phone": "",
        "email": "",
        "rnc": "",
        "address": "",
    });

    const [ phone, setPhone ] = useState("");

    useEffect(() => {
        if (phone && phone.trim() !== "") {
            setPhoneAlert({
                alert: false,
                enText: '',
                esText: ''
            });

            const phoneValue = phone;

            let phoneFormatted = '';
            for (let i = 0; i < phoneValue.length; i++) {
                if (i === 0) {
                    if (phoneValue[i] === "+" || (!(isNaN(phoneValue[i])))) {
                        phoneFormatted += phoneValue[i];
                    }
                } else {
                    if ((!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                        phoneFormatted += phoneValue[i];
                    }
                }
            }

            if (phoneFormatted[0] === "+") {
                
                setNewSupplier({
                    ...newSupplier,
                    "phone": phoneFormatted
                });

                setPhone(formatPhoneNumberOnChange(phoneFormatted));
            } else {
                if (phoneFormatted.trim().length > 0) {
                    setNewSupplier({
                        ...newSupplier,
                        "phone": "+1" + phoneFormatted
                    });
                    setPhone(formatPhoneNumberOnChange("+1" + phoneFormatted));
                } else {
                    setPhone(formatPhoneNumberOnChange(phoneFormatted));
                }
            }
        }
    // eslint-disable-next-line
    }, [phone])

    useEffect(() => {
        if(location.state) {
            setNewSupplier({
                "name": location.state.name,
                "phone": location.state.phone,
                "email": location.state.email,
                "rnc": location.state.rnc,
                "address": location.state.address,
            });
            setPhone(location.state.phone);
        }
    // eslint-disable-next-line
    }, [])

    const [ nameAlert, setNameAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ phoneAlert, setPhoneAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ emailAlert, setEmailAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ rncAlert, setRncAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const invalidPhone = (text) => {
        if (text.length < 10 ) {
            return true;
        } else {
            return false;
        }
    }

    const invalidRNC = (text) => {
        if (text.length === 9 || text.length === 10 || text.length === 11) {
            return false;
        } else {
            return true;
        }
    }

    const getPhoneError = (phone) => {
        if (phone.trim() !== "") {
            if (invalidPhone(phone.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        
    const getEmailError = (email) => {
        if (email.trim() !== "") {
            if(!validateEmail(email.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        
    const getRNCError = (rnc) => {
        if (rnc.trim() !== "") {
            if (invalidRNC(rnc)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }  

    const onChangeForm = e => {
        if(getInputName(e.target.name) === "name") {
            setNameAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if(getInputName(e.target.name) === "phone") {
            setPhoneAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if(getInputName(e.target.name) === "email") {
            setEmailAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if(getInputName(e.target.name) === "rnc") {
            setRncAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if (getInputName(e.target.name) === "phone") {
            const phoneValue = e.target.value.trim().replace(/[()\s-]/g, '');

            let phoneFormatted = '';

            for (let i = 0; (i < phoneValue.length && (i < 16)); i++) {
                if (i === 0) {
                    if (phoneValue[i] === "+" || (!(isNaN(phoneValue[i])))) {
                        phoneFormatted += phoneValue[i];
                    }
                } else {
                    if ((!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                        phoneFormatted += phoneValue[i];
                    }
                }
            }

            setPhone(formatPhoneNumberOnChange(phoneFormatted));

            setNewSupplier({
                ...newSupplier,
                "phone": phoneFormatted
            });
        } else {
            if (getInputName(e.target.name) === "rnc") {
                let newRNC = "";

                const targetValue = e.target.value;

                for (let i = 0; ((i < targetValue.length) && (i < 11)); i++) {
                    if ((targetValue[i] !== " ") && Number.isInteger(Number(targetValue[i]))) {
                        newRNC += targetValue[i];
                    }
                }

                setNewSupplier({
                    ...newSupplier,
                    "rnc": newRNC
                });
            } else {
                setNewSupplier({
                    ...newSupplier,
                    [getInputName(e.target.name)]: e.target.value
                })
            }
            
        }
    }

    const onSave = e => {
        e.preventDefault();

        const supplier = {...newSupplier};

        if (supplier.name.trim() === "") {
            setNameAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;
        }

        if (getPhoneError(supplier.phone)) {
            setPhoneAlert({
                alert: true,
                enText: ' Phone is not valid',
                esText: ' El número de teléfono no es válido'
            });
            return;
        } 
        
        if (getEmailError(supplier.email)) {
            setEmailAlert({
                alert: true,
                enText: ' Email is not valid',
                esText: ' El correo no es válido'
            }); 
            return;
        } 

        if (getRNCError(supplier.rnc)) {
            setRncAlert({
                alert: true,
                enText: ' Cédula or RNC is not valid',
                esText: ' La cédula o RNC no es válido'
            });
            return;
        }

        if (isDuplicated(supplier.rnc)) {
            setRncAlert({
                alert: true,
                enText: ' *** Duplicate ***',
                esText: ' *** Duplicado ***'
            });
            return;
        }
        
        createNewSupplier(supplier);
    }

    const onCancel = e => {
        e.preventDefault();
        
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/clientes', { replace: true });
        }
    }

    async function createNewSupplier(supplier) {
        setLoading(true);
        setAutoFocusName(false);

        try {
            const res = await firebase.useCreateNewSupplier({
                ...supplier,
                "businessId": accountData.id
            });
            
            if (res.msg === "Limit exceeded") {
                setSupplierLimits({
                    "limit": res.limit,
                    "current": res.current,
                    "createNew": false
                });
                
                setAlertData({
                    type: 'error',
                    title: {
                        en: 'Error trying to create the supplier',
                        es: 'Error al intentar crear el proveedor'
                    },
                    code: "Limit exceeded",
                    description: {
                        en : "You can't add more suppliers to this business account because it has exceeded the established limit.",
                        es : "No puedes agregar más proveedores a esta cuenta de negocios porque has excedido el límite establecido."
                    }
                });
                setAlertActive(true);
                setLoading(false);
            } else {

                if (res.msg === "Duplicate RNC") {
                    setSupplierLimits({
                        "limit": res.limit,
                        "current": res.current,
                        "createNew": true
                    });
                    
                    setDuplicatedRNC(res.duplicatedRNC);

                    setAlertData({
                        type: 'error',
                        title: {
                            en: 'Error trying to create the supplier record',
                            es: 'Error al intentar crear el registro al proveedor'
                        },
                        code: "Duplicate barcodes",
                        description: {
                            en : 'It is not possible to assign a cédula or RNC that is already associated with another supplier.',
                            es : 'No es posible asignar una cédula o RNC que ya esté asociada a otro proveedor.'
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                } else { 
                    setSoftAlertActive(true);
                    setSoftAlertData({
                        type : 'success',
                        text: {
                            en : 'Supplier record created',
                            es : 'Registro de proveedor creado',
                        }
                    });

                    navigate('/ver-proveedor', { replace: true, state: res });
                }
            }
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);

            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to create the supplier',
                    es: 'Error al intentar crear el proveedor'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    async function getCustomerLimit() {
        setLoading(true);
        setShowConnectionError(false);

        try {
            const planRef = doc(firebase.db, "plans", accountData.plan);
            const planSnap = await getDocFromServer(planRef);

            const coll = collection(firebase.db, `accounts/${accountData.id}/suppliers`);
            const snapshot = await getCountFromServer(coll);

            setSupplierLimits({
                "limit": planSnap.data().suppliers,
                "current": snapshot.data().count,
                "createNew": snapshot.data().count >= planSnap.data().suppliers ? false : true
            })
            
        } catch (error) {
            console.log(error);
            setShowConnectionError(true);
        }

        setLoading(false);
    }

    useEffect(() => {
        getCustomerLimit();
    // eslint-disable-next-line
    }, [])

    const onTryAgain = e => {
        e.preventDefault();
        getCustomerLimit();
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    useEffect(() => {
        if (accessTo) {
            if(!(accessTo.suppliers)) {
                navigate("/", { replace: true })
            }
        }
    // eslint-disable-next-line
    }, [accessTo]);

    const [ pageTitle, setPageTitle ] = useState(appLanguage.en ? "Add new supplier" : "Agregar nuevo proveedor");

    useEffect(() => {
        if (desktopView) {
            setPageTitle(appLanguage.en ? "Add new supplier" : "Agregar nuevo proveedor");
        } else {
            setPageTitle(appLanguage.en ? "Add new" : "Agregar");
        }

    }, [appLanguage, desktopView]);

    const isDuplicated = (rnc) => {
        if (duplicatedRNC) {
            if (duplicatedRNC.trim() === rnc.trim()) {
                return true;
            } else {
                return false;
            } 
        } else {
            return false
        }
    }

    useEffect(() => {
        if (duplicatedRNC && newSupplier) {
            if (isDuplicated(newSupplier.rnc)) {
                setRncAlert({
                    alert: true,
                    enText: ' *** Duplicate ***',
                    esText: ' *** Duplicado ***'
                });
            }
        }
    // eslint-disable-next-line
    }, [duplicatedRNC, newSupplier])

    useEffect(() => {
        navigate(null, { replace: true, state: newSupplier});
    // eslint-disable-next-line
    }, [newSupplier]);

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Add supplier' : 'Agregar proveedor'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ? 
                        <div className="page-app-settings-item">
                            {accessTo.suppliers ? <Fragment>
                                <div 
                                    className="new-customer-form-container"
                                    style={{
                                        colorScheme: appTheme.dark ? "dark" :"light",
                                    }}
                                >
                                    <form 
                                        autoComplete="off"
                                        autoCorrect="off"
                                        className="new-customer-form"
                                    >
                                        <p className="new-customer-title"><b>{pageTitle} </b> {supplierLimits ? ` - ${supplierLimits.current.toLocaleString()} ${appLanguage.en ? "of" : "de"} ${supplierLimits.limit.toLocaleString()}` : null} </p>
                                        
                                        {loading ? <Spinner/> : <Fragment>
                                            {showConnectionError ? <ConnectionError onTryAgain={onTryAgain}/> : <Fragment>
                                                {supplierLimits ? (supplierLimits.createNew ? <Fragment>
                                                    <p className="new-customer-label">{appLanguage.en ? "Name: " : "Nombre: "}<b>{nameAlert.alert ? (
                                                        appLanguage.en ? nameAlert.enText : nameAlert.esText
                                                    ) : "*"}</b></p>
                                                    <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.name} >{inputsNames.name}</label>
                                                    <input 
                                                        autoFocus={autoFocusName}
                                                        name={inputsNames.name}
                                                        id={inputsNames.name}
                                                        value={newSupplier.name}
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        maxLength={40}
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder={appLanguage.en ? "Example: ElectroShop" : "Ejemplo: ElectroShop"}
                                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}  ${nameAlert.alert ? 'new-customer-input-alert' : appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`}
                                                    />

                                                    <p className="new-customer-label">{appLanguage.en ? "Phone: " : "Teléfono: "}<b>{phoneAlert.alert ? (
                                                        appLanguage.en ? phoneAlert.enText : phoneAlert.esText
                                                    ) : ""}</b></p>
                                                    <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.phone} >{inputsNames.phone}</label>
                                                    <input 
                                                        name={inputsNames.phone}
                                                        id={inputsNames.phone}
                                                        inputMode="numeric"
                                                        value={phone}
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        maxLength={16}
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder={appLanguage.en ? "Example: +1 (809) 995-8646" : "Ejemplo: +1 (809) 995-8646"}
                                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}  ${phoneAlert.alert ? 'new-customer-input-alert' : appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`}
                                                    />

                                                    <p className="new-customer-label">{appLanguage.en ? "Email: " : "Correo: "}<b>{emailAlert.alert ? (
                                                        appLanguage.en ? emailAlert.enText : emailAlert.esText
                                                    ) : ""}</b></p>
                                                    <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.email} >{inputsNames.email}</label>
                                                    <input 
                                                        name={inputsNames.email}
                                                        id={inputsNames.email}
                                                        value={newSupplier.email}
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        maxLength={40}
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder={appLanguage.en ? "Example: juanperez@gmail.com" : "Ejemplo: juanperez@gmail.com"}
                                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}  ${emailAlert.alert ? 'new-customer-input-alert' : appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`}
                                                    />

                                                    <p className="new-customer-label">RNC: <b>{rncAlert.alert ? (
                                                        appLanguage.en ? rncAlert.enText : rncAlert.esText
                                                    ) : ""}</b></p>
                                                    <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.rnc} >{inputsNames.rnc}</label>
                                                    <input 
                                                        name={inputsNames.rnc}
                                                        id={inputsNames.rnc}
                                                        inputMode="numeric"
                                                        value={newSupplier.rnc}
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder={appLanguage.en ? "Example: 123-1234567-1" : "Ejemplo: 123-1234567-1"}
                                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}  ${rncAlert.alert ? 'new-customer-input-alert' : appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`} 
                                                    />

                                                    <p className="new-customer-label">{appLanguage.en ? "Address:" : "Dirección:"}</p>
                                                    <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.address} >{inputsNames.address}</label>
                                                    <textarea 
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder={appLanguage.en ? "Example: Calle Mella, Enriquillo, Santo Domingo..." : "Ejemplo: Calle Mella, Enriquillo, Santo Domingo..."}
                                                        style={ appTheme.dark ? {colorScheme: "dark"} : {colorScheme: "light"}}
                                                        className={`new-custmer-textarea-address ${appTheme.dark ? "new-custmer-textarea-dark dar-sec-bg" : "new-custmer-textarea-light lig-sec-bg" }`}
                                                        id={inputsNames.address}
                                                        name={inputsNames.address}
                                                        value={newSupplier.address}
                                                        maxLength={200}
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                    />

                                                    <div className="new-customer-bottons-container2">
                                                        <button 
                                                            onClick={e => onCancel(e)} 
                                                            className="new-customer-bottons-button-save"
                                                        >
                                                            <img src={crossIcon} alt="Cancel" />
                                                            {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                                        </button>

                                                        <button 
                                                            onClick={e => onSave(e)}
                                                            className="new-customer-bottons-button-save"
                                                        >
                                                            <img src={saveIcon} alt="Save" />
                                                            {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                        </button>
                                                    </div>
                                                </Fragment> : 
                                                    <div className='authorize-access-page-alert-cont'>
                                                        <img src={warningIcon} alt="alert" />
                                                        {appLanguage.en ? <Fragment>
                                                            <p><b>You cannot add more suppliers</b></p>
                                                            <p><b>{supplierLimits.current.toLocaleString()} of {supplierLimits.limit.toLocaleString()}</b> suppliers created</p>
                                                            <br/>
                                                            <p>Only {supplierLimits.limit.toLocaleString()} suppliers can be created per basic account. You can delete an existing supplier to add another or upgrade to a more advanced plan.</p>
                                                        </Fragment> : <Fragment>
                                                            <p><b>No puedes agregar más proveedores</b></p>
                                                            <p><b>{supplierLimits.current.toLocaleString()} de {supplierLimits.limit.toLocaleString()}</b> proveedores creadas</p>
                                                            <br/>
                                                            <p>Sólo se pueden crear {supplierLimits.limit.toLocaleString()} proveedores por plan básica. Puedes eliminar un proveedor existente para agregar otra o actualizarte a un plan más avanzado.</p>
                                                        </Fragment>}
                                                    </div>
                                                ) : null}
                                            </Fragment>}
                                        </Fragment>}
                                        <br/>
                                    </form>
                                </div>
                            </Fragment> : null}
                        </div>
                : 
                        <div className="page-app-customers-item">
                            <div className="customers-options-container">
                                <div className="customers-options-container-item">
                                    <SettingsActionRequired />
                                </div> 
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default CreateSupplierPage;